.background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('../../../public/BG@3x.png');
  background-size: cover;
}

@media (min-aspect-ratio: 1/1) {
  .background {
    background-image: url('../../../public/bg_pc.png');
    background-size: 100% 100%;
    display: flex;
    justify-self: center;
    overflow: auto;
  }

  .bodyWrapper {
    width: 100%;
    height: 100%;
  }
}

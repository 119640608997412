.artWord {
  font-weight: 900;
  font-size: 3.2rem;
  text-align: center;
  font-family: Helvetica;
  color: #ffffff;
  -webkit-text-stroke: 0.1rem #000000;
  text-shadow: 0px 0.2rem 0px #000000;
}

.container {
  width: 100%;
  height: 100%;
}

.centerPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.playWrapper {
  top: 3rem;
  position: relative;
  display: inline-block;
  width: 30.3rem;
  height: 57.7rem;
  background-color: transparent;
  background-image: url('../../../public/frame.png');
  background-size: 100% 100%;
}

.playContent {
  margin: 44.1vw 13.2vw 8.5vw 13.2vw;
}

.bingoContent {
  width: 100%;
  height: 99.4vw;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.bingoTips {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bingoText {
  margin-top: 9px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  span:nth-child(even) {
    font-weight: 500;
    border-radius: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1.9rem;
    padding: 4px 12px;
  }
}

.playContent__betBtn {
  width: 56vw;
  height: 13.3vw;
  .buttonBetImg {
    width: 5.5rem;
    height: 2.5rem;
  }
  .buttonBingoImg {
    width: 7.4rem;
    height: 2.3rem;
  }

  p {
    line-height: 2.6rem;
    font-size: 1.6rem;

    &:last-child {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
}

.minmax {
  display: flex;
  gap: 1em;

  span {
    font-size: 0.7em;
    margin-left: 0.2em;
  }
}

.minmaxError {
  color: red;
  height: 1em;
}

.bigsmall {
  button {
    width: 100%;
    font-weight: 900;
    font-family: Helvetica;
    color: #ffffff;
    -webkit-text-stroke: 0.1rem #000000;
    text-shadow: 0px 0.2rem 0px #000000;
  }
}

.mobile {
  .input {
    margin-bottom: 10px;
  }

  .bigsmall {
    button {
      height: 5rem;
      font-size: 2rem;
      line-height: 3.8rem;
      margin-bottom: 10px;
    }
  }
}

.right {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pc {
  display: flex;
  width: 100%;
  gap: 40px;
  height: 100%;

  .left {
    width: 50%;
  }

  .bigsmall {
    display: flex;
    gap: 20px;

    button {
      height: 10rem;
      font-size: 5rem;
      line-height: 3.8rem;
    }
  }
}

.mobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 4.8rem;
  height: 4.8rem;
  background: #ffd812;
  border: 0.15rem solid #000000;
  border-radius: 50%;
  margin-left: 0.9rem;
  svg {
    height: 2rem;
    width: 2rem;
  }
}

.pc {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 6rem;
  height: 6rem;
  background: #ffd812;
  border: 0.15rem solid #000000;
  border-radius: 50%;
  margin-left: 1.4rem;
  svg {
    height: 3.1rem;
    width: 3.1rem;
  }
}

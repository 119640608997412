.mobile {
  padding: 1.4rem 1.4rem;
  overflow: hidden;

  .contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.6rem;
    .button {
      background-image: url('../../../public/rectangle.png');
      background-size: 100% 100%;
      height: 3rem;
      padding: 0.6rem 0.5rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: white;
      margin-bottom: 1.2rem;
    }
  }

  .footerButtonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 1rem;
  }
}

.pc {
  margin: 2.5rem 4.4rem 3rem 4.4rem;
  overflow: hidden;

  .contentWrapper {
    margin: 3rem 8rem;
    width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    width: inherit;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    width: 100%;
    .button {
      display: flex;
      align-items: center;
      background-image: url('../../../public/rectangle_pc.png');
      background-size: 100% 100%;
      padding: 1.2rem 4rem;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: white;
    }
  }
}

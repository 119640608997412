.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 5.2rem;
    }

    .warning {
      font-weight: 500;
      font-size: 2.4rem;
      color: #000000;
    }
  }

  .warningText {
    margin-top: 1.6rem;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;
    color: #707070;
  }

  .defaultBtn {
    margin-top: 5.5rem;
    width: 24rem;
    height: 6rem;
  }
}

.pc {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 7rem;
      height: 6rem;
    }
    .warning {
      font-weight: 600;
      font-size: 4rem;
      color: #000000;
    }
  }

  .warningText {
    margin-top: 1.6rem;
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    color: #707070;
  }

  .defaultBtn {
    margin-top: 8rem;
    width: 40rem;
    height: 10rem;
  }
}

.artWord {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.8rem;
  text-align: center;
  font-family: Helvetica;
  color: #ffffff;
  -webkit-text-stroke: 0.1rem #000000;
  text-shadow: 0px 0.2rem 0px #000000;
}

.wrapper {
  width: 100%;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1% 2%;

  :global .ant-input-number-input {
    height: unset;
  }
}

.input {
  font-weight: 500;
  flex: 1;
  color: #000000;
}

.text {
  color: #ff9903;
}

.pc {
  border: 2px solid #000000;
  border-radius: 2rem;

  .input {
    font-size: 4rem;
  }

  .text {
    font-size: 4rem;
  }
}

.mobile {
  .input,
  .text {
    font-size: 2.5rem;
  }
}

.container {
  width: 100%;
  height: 100%;
}

.centerPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.playWrapper {
  top: 3rem;
  position: relative;
  display: inline-block;
  width: 30.3rem;
  height: 57.7rem;
  background-color: transparent;
  background-image: url('../../../public/frame.png');
  background-size: 100% 100%;
}

.playWrapper_cutdown {
  background-image: url('../../../public/frame.png');
}

.playContent {
  margin: 44.1vw 13.2vw 8.5vw 13.2vw;

  .buttonBigImg {
    width: 4.1rem;
    height: 2.3rem;
  }
  .buttonSmallImg {
    width: 8.1rem;
    height: 2.3rem;
  }
}

.button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  text-decoration: none;
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
  color: #fff;
  background: #FF9903;
}

.button:active {
  transform: translate(0, 0.375em);
}

.mobile {
  width: 26.6vw;
  height: 8vw;
  box-shadow: 2px 2px 0px rgba(255, 153, 3, 0.3);
  border-radius: 43.5px;
}

.pc {
  width: 100%;
  height: 6rem;
  font-size: 2.4rem;
  box-shadow: 4px 4px 0px rgba(255, 153, 3, 0.3);
  border-radius: 43.5px;
}
.contentWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.content__bg {
  width: 132.2rem;
  height: 80.5rem;
  background-image: url('../../../public/frame_pc.png');
  background-size: 100% 100%;
}

.content__bg_cutdown {
  background-image: url('../../../public/frame_pc_cutdown.png');
}

.content__wrapper {
  margin-top: 38.1rem;
  margin-left: 13.7rem;
  margin-right: 13.7rem;
  margin-bottom: 7rem;
  height: 34.5rem;

  display: flex;
}

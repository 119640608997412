.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.countdown {
  display: flex;
  gap: 2rem;
  flex-grow: 1;
  align-items: center;
}

.number {
  background-image: url('../../../public/cutdown.svg');
  background-size: 100% 100%;
  font-family: Roboto;
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.truck {
  position: absolute;
  animation-name: truck;
  bottom: 10px;
  margin-left: -42px;
}

.bar {
  animation-name: bar;
  height: 10px;
  background-color: #ff9601;
  align-self: flex-start;
}

.bar_bg {
  height: 10px;
  width: 100%;
  background-color: #ffcb82;
  align-self: flex-start;
  position: relative;
}

.mobile {
  &.wrapper {
    padding: 120px 50px 50px 50px;
  }

  .number {
    width: 6rem;
    height: 6rem;
    font-size: 3.5em;
  }
}

.pc {
  .number {
    width: 12rem;
    height: 12rem;
    font-size: 4.4em;
  }
}

@keyframes truck {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

@keyframes bar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.body_init {
  background: #488bff;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.body {
  background: rgba(0, 0, 0, 0.6);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 0;
}

.bingo_mobile {
  width: 16rem;
  margin-bottom: 2.1rem;
}

.bingo_pc {
  width: 36rem;
  margin-bottom: 2.1rem;
}

.wrapper {
  width: 20rem;
  height: 6rem;
  position: relative;
}

.wrapper_mobile {
  width: 20rem;
  height: 6rem;
  position: relative;
}

.circle {
  width: 2rem;
  height: 2rem;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

.wrapper_mobile .circle {
  width: 1rem;
  height: 1rem;
  left: 25%;
  animation: circle_mobile 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 6rem;
    height: 0.5rem;
    border-radius: 5rem 5rem 2.5rem 2.5rem;
    transform: scaleX(1.7);
  }
  40% {
    height: 2rem;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

@keyframes circle_mobile {
  0% {
    top: 3rem;
    height: 0.5rem;
    border-radius: 5rem 5rem 2.5rem 2.5rem;
    transform: scaleX(1.7);
  }
  40% {
    height: 1rem;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

.circle_2 {
  left: 45%;
  animation-delay: 0.2s;
}
.circle_3 {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.wrapper_mobile .circle_2 {
  left: 48%;
  animation-delay: 0.2s;
}
.wrapper_mobile .circle_3 {
  left: auto;
  right: 25%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}

.extra__data__wrapper__mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34.3rem;
  margin-top: 2.8rem;
}

.extra__data__wrapper__pc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 68.6rem;
  margin-top: 2.8rem;
}

.sync__maintitle__mobile {
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: #ffffff;
  display: block;
  white-space: pre-line;
}

.sync__maintitle__pc {
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.8rem;
  text-align: center;
  color: #ffffff;
  display: block;
  white-space: pre-line;
}

.sync__subtitle__mobile {
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
  display: block;
  white-space: pre-line;
  margin-top: 1.6rem;
}

.sync__subtitle__pc {
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4rem;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
  display: block;
  white-space: pre-line;
  margin-top: 1.6rem;
}
